@import url("https://fonts.googleapis.com/css?family=Fira+Sans+Condensed:200,300,400,500,600,700,800&display=swap&subset=latin-ext");

html {
  position: relative;
  min-height: 100%;
}

body {
  min-height: 100%;
  font-family: "Fira Sans Condensed", sans-serif;

  ul,
  p,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  a {
    margin: 0;
    padding: 0;
    font-weight: 300;
  }

  p {
    font-size: 15px;
  }

  .btn-default {
    border-radius: 0;
    border: 1px solid #fff;
    background: #fff;
    text-transform: uppercase;
    font-size: 14px;
    padding: 10px 20px;
    transition: all 0.3s;
    color: @gray-dark;

    &:hover {
      background: @gray;
      border-color: @gray;
      color: #fff;
    }

    &.btn-yellow {
      background: @color1;
      border-color: @color1;

      &:hover {
        background: lighten(@color1, 10%);
        border-color: lighten(@color1, 10%);
        color: @gray-dark;
      }

      &.btn-border {
        background: none;
        color: @color1;

        &:hover {
          background: @color1;
          border-color: @color1;
          color: @gray-dark;
        }
      }
    }

    &.btn-black {
      background: #333333;
      border-color: #333333;

      &:hover {
        background: lighten(#333333, 10%);
        border-color: lighten(#333333, 10%);
        color: @color1;
      }

      &.btn-border {
        background: none;
        color: #333333;

        &:hover {
          background: #333333;
          border-color: #333333;
          color: @color1;
        }
      }
    }
  }

  a {
    text-decoration: none;

    &:hover,
    &:active,
    &:focus {
      text-decoration: none;
    }
  }

  .m-t-5 {
    margin-top: 5px;
  }
  .m-t-10 {
    margin-top: 10px;
  }
  .m-t-15 {
    margin-top: 15px;
  }
  .m-t-20 {
    margin-top: 20px;
  }
  .m-t-25 {
    margin-top: 25px;
  }
  .m-t-30 {
    margin-top: 30px;
  }
  .m-b-5 {
    margin-bottom: 5px;
  }
  .m-b-10 {
    margin-bottom: 10px;
  }
  .m-b-15 {
    margin-bottom: 15px;
  }
  .m-b-20 {
    margin-bottom: 20px;
  }
  .m-b-25 {
    margin-bottom: 25px;
  }
  .m-b-30 {
    margin-bottom: 30px;
  }
  .p-t-5 {
    padding-top: 5px;
  }
  .p-t-10 {
    padding-top: 10px;
  }
  .p-t-15 {
    padding-top: 15px;
  }
  .p-t-20 {
    padding-top: 20px;
  }
  .p-t-25 {
    padding-top: 25px;
  }
  .p-t-30 {
    padding-top: 30px;
  }
  .p-b-5 {
    padding-bottom: 5px;
  }
  .p-b-10 {
    padding-bottom: 10px;
  }
  .p-b-15 {
    padding-bottom: 15px;
  }
  .p-b-20 {
    padding-bottom: 20px;
  }
  .p-b-25 {
    padding-bottom: 25px;
  }
  .p-b-30 {
    padding-bottom: 30px;
  }
}

.products-flexbox {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  .product-service {
    position: relative;
    margin-top: 40px;
    width: calc(100% / 3 - 25px);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    text-align: center;
    border: 1px solid @gray-lighter;
    background: #0d0d0d;
    padding: 20px;
    transition: all 0.3s;

    img {
      max-width: 100%;
      max-height: 250px;
    }

    .service-footer {
      width: 100%;

      p.price {
        font-size: 32px;
        margin: 15px 0 0;
        font-weight: 500;
        color: @color1;
        line-height: 1;

        .price__curr {
          font-size: 24px;
        }

        small {
          font-size: 20px;
          color: #bdbdbd;
          margin-left: 5px;
          position: relative;

          span {
            font-size: 18px;
          }

          &::after {
            content: "";
            width: 100%;
            min-width: 10px;
            height: 2px;
            display: inline-block;
            background-color: #bdbdbd;
            z-index: 11;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
          }
        }
      }

      p.footer-name {
        font-size: 20px;
        font-weight: 400;
        margin: 5px 0 15px 0;
        color: @gray-lighter;
      }

      .btn {
        display: block;

        &:last-child {
          margin-top: 10px;
        }
      }
    }

    &:hover {
      border-color: @gray;
    }

    .badge {
      position: absolute;
      top: 5px;
      padding: 10px 25px;
      display: inline-block;
      line-height: 1;
      background-color: #ff6663;
      color: #fff;
      border-radius: 0;
      font-weight: 400;
      text-transform: uppercase;
      &--new {
        left: 5px;
      }
      &--promotion {
        right: 5px;
      }
    }

    &.product-hidden {
      padding: 0;
      margin: 0;
      visibility: hidden;
    }
  }
}

.news-flexbox {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  article {
    width: 290px;
    text-align: center;
    margin-top: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;

    .news-image {
      height: 170px;
      display: flex;
      align-items: center;
      justify-content: center;
      overflow: hidden;
      transition: all 0.3s;

      img {
        width: 100%;
        left: 0;
        transition: all 0.3s;
        position: relative;
      }
    }

    p {
      color: @color1;
      font-size: 11px;
      text-transform: uppercase;
      margin-top: 15px;
      font-weight: 400;
    }

    h2 {
      padding: 15px 10px;
      font-size: 22px;

      a {
        font-weight: 400;
        color: #000;
        text-decoration: none;
        transition: color 0.3s;
      }
    }

    .btn-link {
      text-transform: uppercase;
      font-size: 12px;
      text-decoration: none;
      color: #000;
    }

    &:hover {
      .news-image {
        box-shadow: 0px 4px 7px -3px rgba(0, 0, 0, 0.14);

        img {
          width: 105%;
          left: -2.5%;
        }
      }

      h2 {
        a {
          color: @color1;
        }
      }
    }

    &.article-hidden {
      padding: 0;
      margin: 0;
      visibility: hidden;
    }
  }
}

.pagination {
  margin-bottom: 0;
  margin-top: 50px;

  li {
    a {
      background: #fff;
      border: 1px solid @gray-lighter;
      color: @color1;
    }

    &.active {
      a {
        background: @color1;
        border-color: @color1;
      }
    }

    &:first-child,
    &:last-child {
      a {
        border-radius: 0;
      }
    }
  }
}

.breadcrumb {
  display: flex;
  justify-content: center;
  margin-top: 30px;
  padding: 0;
  margin-bottom: 0;
  background-color: #fff;
  flex-wrap: wrap;

  a {
    color: inherit;
  }
  > .active {
    color: @color1;
  }

  &.client-panel-breadcrumb {
    margin-bottom: 30px;
  }
}

.alert {
  margin-bottom: 0;
  color: #fff;
  border: 0 none;

  .close {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 5px;
    right: 25px;
    opacity: 1;
    line-height: 1;

    &:hover,
    &:active {
      color: #fff;
    }
  }

  p {
    font-weight: 700;
  }

  &.alert-success {
    color: #333333;
    background-color: rgba(@color1, 0.85);
    text-align: center;
    border: 0 none;
  }

  &.alert-warning {
    color: #fff;
    background-color: rgba(#ff6663, 0.95);
    text-align: center;
    border: 0 none;
  }
}

div#section-header {
  position: relative;
  z-index: 222;

  nav.navbar {
    margin-bottom: 0;
    border-radius: 0;
    border: none;
    min-height: auto;
    background: -moz-linear-gradient(
      top,
      rgba(0, 0, 0, 0.6) 0%,
      rgba(0, 0, 0, 0.2) 40%,
      rgba(0, 0, 0, 0) 100%
    ); /* FF3.6-15 */
    background: -webkit-linear-gradient(
      top,
      rgba(0, 0, 0, 0.6) 0%,
      rgba(0, 0, 0, 0.2) 40%,
      rgba(0, 0, 0, 0) 100%
    ); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0.6) 0%,
      rgba(0, 0, 0, 0.2) 40%,
      rgba(0, 0, 0, 0) 100%
    ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#000000', endColorstr='#00000000',GradientType=0 ); /* IE6-9 */

    .navbar-brand {
      height: auto;
      margin-right: 25px;
    }

    ul.navbar-nav {
      li {
        a {
          padding: 35px 15px;
          color: #fff;
          text-transform: uppercase;
          font-size: 14px;
        }

        &.item-search {
          height: 90px;
          display: flex;
          align-items: center;
          padding: 15px 0 15px;
          margin-left: 10px;

          .search-form {
            display: flex;
            justify-content: center;
            align-items: center;

            #searchInput {
              background-color: rgba(#fff, 0.25);
              border: 1px solid @color1;
              padding: 5px 10px;
              margin-right: 5px;
              color: #fff;

              &::placeholder {
                color: #fff;
              }
            }
          }
          #searchButton {
            background-color: transparent;
            border: 0 none;
          }
        }

        &.shop-panel {
          display: flex;
          align-items: center;
          height: 90px;

          a {
            padding: 0 15px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            text-align: center;
            font-size: 12px;

            span {
              line-height: 2;
            }
          }
        }
      }
    }

    .navbar-toggle {
      padding: 0;
      margin-top: 38px;
      border: none;

      span {
        background-color: #fff;
      }

      &:hover,
      &:focus {
        background: none;
      }
    }
  }

  &.header-subpage {
    nav.navbar {
      background: #0d0d0d;

      .navbar-brand {
        height: auto;
      }

      ul.navbar-nav {
        li {
          a {
            padding: 35px 15px;
            color: #fff;
            text-transform: uppercase;
            font-size: 14px;
          }
          &.shop-panel {
            display: flex;
            align-items: center;
            height: 90px;
            margin-left: 25px;

            a {
              padding: 15px;
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: center;
              text-align: center;
              font-size: 12px;

              span {
                line-height: 2;
              }
            }
          }
        }
      }
    }
  }
}

div#section-main-carousel {
  background: url(../img/backgrounds/fotolia_114122893.jpg);
  background-size: cover;
  background-attachment: fixed;
  position: relative;
  top: -90px;
  padding-bottom: 60px;
  z-index: 111;
  height: 600px;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;

  p {
    color: #fff;
    font-size: 18px;
    margin-bottom: 30px;
  }

  .owl-carousel-slider {
    .item {
      overflow: hidden;

      h1 {
        color: #fff;
        font-size: 70px;

        strong {
          font-size: 55px;
        }
      }

      p {
        font-size: 20px;
      }
    }

    .owl-controls {
      display: none;
    }
  }

  .btn {
    margin-top: 30px;
    position: relative;
    z-index: 222;

    span {
      font-size: 10px;
      margin-left: 80px;
    }
  }

  .main-carousel-contact {
    position: absolute;
    bottom: 50px;
    text-align: right;
    width: 100%;

    .contact-service {
      max-width: 1140px;
      margin: 0 auto;
    }

    a {
      color: #fff;
      font-size: 14px;
      text-transform: uppercase;
      text-decoration: none;

      img {
        max-height: 40px;
        margin-left: 10px;
      }

      &:first-child {
        font-size: 25px;
        color: @color1;
        font-weight: 500;
      }
    }
  }
}

div#section-main-carousel-mobile {
  background: url(../img/backgrounds/fotolia_114122893.jpg) center right
    no-repeat;
  background-size: cover;
  position: relative;
  padding: 50px 0;
  z-index: 111;
  text-align: center;

  p {
    color: #fff;
    font-size: 18px;
    margin-bottom: 30px;
  }

  .owl-carousel-slider {
    .item {
      overflow: hidden;

      h1 {
        color: #fff;
        font-size: 40px;

        strong {
          font-size: 25px;
        }
      }
    }

    .owl-controls {
      display: none;
    }
  }

  .btn {
    margin-top: 30px;
    position: relative;
    z-index: 222;

    span {
      font-size: 10px;
      margin-left: 80px;
    }
  }

  .main-carousel-contact {
    width: 100%;
    margin-top: 30px;

    .contact-service {
      max-width: 100%;
      margin: 0 auto;
    }

    a {
      color: #fff;
      font-size: 14px;
      text-transform: uppercase;
      text-decoration: none;

      img {
        max-height: 40px;
        margin-left: 10px;
      }

      &:first-child {
        font-size: 25px;
        color: @color1;
        font-weight: 500;
      }
    }
  }
}

div#section-cta {
  padding: 30px 0;
  background: @color1;
  position: relative;
  margin-top: -90px;
  text-align: center;

  p {
    &:first-child {
      font-size: 40px;
      font-weight: 400;
    }
  }

  &.cta-subpage {
    margin-top: 0;
  }
}

div#section-offer {
  display: flex;
  flex-wrap: wrap;

  .offer-service {
    width: calc(100% / 3);
    height: 280px;
    padding: 30px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
    transition: all 0.3s;
    border: 1px solid transparent;

    h2 {
      color: #fff;
    }

    p {
      color: #fff;
      margin-top: 10px;
      padding-right: 70px;
    }

    .btn {
      align-self: flex-start;
    }

    .img-absolute {
      position: absolute;
      bottom: 16px;
      right: 20px;
    }

    &.bg1 {
      background: url(../img/pictures/fotolia_113419827.jpg) center;
      background-size: cover;
    }

    &.bg2 {
      background: url(../img/pictures/fotolia_140912191.jpg) center;
      background-size: cover;
    }

    &.bg3 {
      background: url(../img/pictures/fotolia_96842625.jpg) center;
      background-size: cover;
    }

    &.bg4 {
      background: url(../img/pictures/fotolia_80337320.jpg) center;
      background-size: cover;
    }

    &.bg5 {
      background: url(../img/pictures/fotolia_94591158.jpg) center;
      background-size: cover;
    }

    &.bg6 {
      background: #222;

      .btn {
        background: #c34026;
        border-color: #c34026;
        color: #fff;
      }
    }

    &:hover {
      border: 1px solid @color1;
    }
  }
}

div#section-colors {
  padding: 50px 0;

  .colors-flexbox {
    margin-bottom: 50px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;

    img {
      width: 35%;
      align-self: center;
    }

    .flexbox-description {
      width: 55%;

      p {
        &:first-child {
          font-size: 40px;
          font-weight: 400;
        }
      }
    }
  }

  .bottom-flexbox {
    display: flex;
    justify-content: space-between;

    .flexbox-service {
      display: flex;
      align-items: center;
      width: 15%;

      .service-image {
        min-height: 50px;
        max-height: 50px;
        min-width: 50px;
        max-width: 50px;
        border-radius: 50%;
        margin-right: 15px;
      }
    }
  }
}

div#section-about {
  padding: 50px 0;
  text-align: center;
  background: @gray-lighter;

  p {
    &:first-child {
      font-size: 40px;
      font-weight: 400;
    }
  }
}

div#section-news {
  padding: 50px 0;
  text-align: center;

  .news-heading {
    p {
      &:first-child {
        font-size: 40px;
        font-weight: 400;
      }
    }
  }
}

div#o-firmie-section-welcome {
  .welcome-flexbox {
    position: relative;

    .img-absolute {
      position: absolute;
      top: 0;
      right: 0;
      width: 50%;
      height: 100%;
      overflow: hidden;
      display: flex;
      align-items: center;

      img {
        width: 100%;
      }
    }

    .flexbox-description {
      width: 45%;
      padding: 30px 0;

      h1 {
        font-size: 40px;
        font-weight: 400;
        margin-bottom: 10px;
      }
    }

    &.flexbox-reverse {
      .img-absolute {
        right: auto;
      }

      .flexbox-description {
        width: 45%;
        float: right;
      }
    }
  }
}

div#kategoria-section-welcome {
  padding-top: 50px;

  .welcome-flexbox {
    text-align: center;

    h1 {
      font-size: 40px;
      font-weight: 400;
      margin-bottom: 10px;
    }
  }
}

div#kategoria-section-products {
  padding-bottom: 50px;

  .category-list {
    margin-top: 25px;
    margin-bottom: -5px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    list-style: none;

    li {
      background-color: @color1;
      margin: 5px;

      a {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        padding: 15px 25px;
        color: inherit;
        font-size: 14px;
        font-weight: 700;
      }

      &.active {
        background-color: #0d0d0d;
        color: #fff;
      }
    }
  }
}

div#produkt-section-content {
  margin-top: 50px;
  background: @gray-lighter;
  position: relative;

  .single-product-flexbox {
    display: flex;
    justify-content: space-between;

    .flexbox-gallery {
      width: 45%;
      padding: 75px 0;
      background: #0d0d0d;

      .lSSlideOuter {
        img {
          margin: 0 auto;
          display: block;
          max-height: 550px;
        }
        .lSPager.lSGallery {
          li {
            border: 1px #ececec;
            height: 125px;
          }
        }
      }
    }

    .flexbox-info {
      width: 50%;
      padding: 75px 20px 75px 0;

      .info-description {
        max-width: 600px;
      }

      p.info-name {
        font-size: 40px;
        font-weight: 400;
        margin-bottom: 10px;
      }

      .product-features {
        margin-top: 50px;
        li {
          margin-top: 5px;
          font-size: 16px;
        }
      }

      .color-select {
        margin-top: 50px;
        display: flex;
        flex-wrap: wrap;

        h3 {
          font-weight: 400;
          font-size: 16px;
          width: 100%;
        }

        .radio-wrapper {
          margin-top: 5px;
          margin-right: 10px;
          display: flex;
          align-items: center;
          line-height: 1;
          position: relative;

          input {
            opacity: 0;
            visibility: hidden;
            position: absolute;
            &:checked {
              & + label {
                background-color: rgba(@color1, 0.5);
              }
            }
          }

          label {
            display: flex;
            align-items: center;
            padding: 5px;
            .color-dot {
              height: 25px;
              width: 25px;
              border-radius: 50%;
              margin-right: 5px;
            }
          }
        }

        .select-wrapper {
          margin-top: 15px;
          border: 2px solid #f9f9f9;
          background: #fff;

          select {
            border: none;
            background: none;
            padding: 13px 100px 13px 13px;
            width: 100%;
          }
        }
      }

      .price-wrapper {
        margin-top: 60px;

        .product-price {
          font-size: 40px;
          font-weight: 700;

          .product-price__label {
            font-weight: 400;
            font-size: 20px;
            margin-right: 5px;
          }

          .product-price__curr {
            font-size: 20px;
          }
          small {
            font-size: 20px;
            color: #bdbdbd;
            margin-left: 5px;
            position: relative;

            span {
              font-size: 18px;
            }

            &::after {
              content: "";
              width: 100%;
              min-width: 10px;
              height: 2px;
              display: inline-block;
              background-color: #bdbdbd;
              z-index: 11;
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
            }
          }
        }

        .add-to-cart {
          display: flex;
          align-items: center;

          .quantity-selector {
            margin-right: 5px;
            display: flex;
            align-items: center;

            .input-number-decrement {
              padding: 10px;
              margin-right: 2px;
            }

            .input-number {
              width: 50px;
              height: 42px;
              padding: 5px;
              font-size: 16px;
              font-weight: 500;
              border-radius: 0;
              border: 1px solid #0d0d0d;
            }

            .input-number-increment {
              padding: 10px;
              margin-left: 2px;
            }
          }
          p {
            text-transform: uppercase;
            font-size: 16px;
            font-weight: 500;
            margin-left: 10px;
            font-style: italic;
          }

          .btn--add-to-cart {
          }
        }
      }

      .product-info {
        padding: 20px 0;
        p {
          font-size: 15px;
          line-height: 28px;
          margin-bottom: 10px;
          span {
            font-weight: 600;
            display: block;
            margin-top: 10px;
          }
        }
      }

      .product-docs {
        margin-top: 50px;

        h3 {
          margin-bottom: 5px;
          font-size: 16px;
          font-weight: 500;
        }
      }
    }
  }
}

.section-cross-sell {
  padding-top: 50px;
  padding-bottom: 50px;

  h2 {
    font-size: 40px;
    font-weight: 400;
    text-align: center;
  }
}

html[data-lc-type="image"] #lightcase-content,
html[data-lc-type="video"] #lightcase-content {
  background-color: #fff;
}

div#aktualnosc-section-content {
  padding-bottom: 30px;

  article.single-article {
    text-align: center;
    margin-top: 30px;

    img {
      width: 100%;
      max-width: 500px;
    }

    header {
      p {
        color: @color1;
        font-size: 11px;
        text-transform: uppercase;
        margin-top: 15px;
        font-weight: 400;

        &:before {
          content: "Opublikowano";
          font-weight: 500;
          margin-right: 5px;
        }
      }

      h1 {
        font-size: 35px;
        margin-top: 20px;
        font-weight: 500;
        color: @color1;
      }
    }

    section {
      border-top: 1px solid @gray-lighter;
      margin-top: 30px;
      padding-top: 30px;

      h2 {
        font-size: 26px;
        font-weight: 400;
      }

      p {
        font-weight: 400;

        strong {
          color: @color1;
        }
      }
    }

    &.text-left {
      text-align: left;

      header {
        h1 {
          text-align: center;
        }
      }
    }
  }
}

div#dystrybutorzy-section-content {
  .section-heading {
    text-align: center;

    p {
      &:first-child {
        font-size: 40px;
        font-weight: 400;
      }
    }
  }

  .panel-group {
    margin-top: 30px;
    margin-bottom: 0;

    .panel {
      border: none;
      background: none;
      box-shadow: none;
      margin-top: 10px;

      .panel-heading {
        background: none;
        padding: 10px;
        border-radius: 0;
        border: 1px solid @color1;

        h4 {
          font-size: 22px;
          font-weight: 700;

          a {
            text-decoration: none;

            span {
              font-size: 12px;
              margin-left: 5px;
              top: 0;
            }
          }
        }

        &.actived {
          background: @color1;
          color: #fff;
        }
      }

      .panel-body {
        border: none;
        padding: 0;

        table {
          margin-bottom: 0;

          tr {
            td {
              border-top: none;

              p {
                font-size: 16px;
                font-weight: 400;
              }

              a {
                font-size: 16px;
                color: @color1;
                font-weight: 700;
              }

              &:before {
                display: block;
                text-transform: uppercase;
                font-size: 11px;
                color: @gray-light;
              }

              &:nth-child(1) {
                &:before {
                  content: "Nazwa";
                }
              }

              &:nth-child(2) {
                &:before {
                  content: "Ulica";
                }
              }

              &:nth-child(3) {
                &:before {
                  content: "Miasto";
                }
              }

              &:nth-child(4) {
                text-align: right;

                &:before {
                  content: "Telefon";
                }
              }
            }

            &:nth-child(2n) {
              background: @gray-lighter;
            }
          }
        }
      }
    }
  }

  .content-cta {
    background: @color1;
    padding: 30px 0;
    text-align: center;
    margin-top: 50px;

    p {
      font-size: 40px;
      font-weight: 400;
    }

    .btn {
      margin-top: 30px;
    }
  }
}

div#kontakt-section-content {
  text-align: center;
  padding-bottom: 50px;

  iframe {
    border: none;
    width: 100%;
  }

  .content-info {
    margin-top: 50px;

    p {
      font-size: 28px;
      color: #000;
    }

    a {
      font-size: 28px;
      color: @color1;
      font-weight: 400;
      text-decoration: none;
    }
  }

  form {
    margin-top: 50px;

    label {
      font-weight: 400;
    }

    input,
    textarea {
      border-radius: 0;
      box-shadow: none;
      border-color: #000;
    }

    input {
      height: 50px;
    }

    textarea {
      min-height: 130px;
    }
  }
}
div#delivery-section-content {
  min-height: 85vh;

  .page-heading {
    margin-top: 50px;
    text-align: center;

    h1 {
      font-size: 40px;
      font-weight: 400;
    }

    p {
      margin-top: 10px;
    }
  }

  .text-content {
    margin-top: 50px;

    ul {
      padding-left: 2rem;
    }
  }
}

div#returns-section-content {
  min-height: 85vh;

  .page-heading {
    margin-top: 50px;
    text-align: center;

    h1 {
      font-size: 40px;
      font-weight: 400;
    }

    p {
      margin-top: 10px;
    }
  }

  .text-content {
    margin-top: 50px;

    ul {
      padding-left: 2rem;
    }
  }
}

div#policy-section-content {
  min-height: 85vh;

  .page-heading {
    margin-top: 50px;
    text-align: center;

    h1 {
      font-size: 40px;
      font-weight: 400;
    }

    p {
      margin-top: 10px;
    }
  }

  .policy-text {
    margin-top: 50px;
  }
}

div#section-footer {
  background: #0d0d0d;
  padding-top: 20px;

  .footer-flexbox {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    border-bottom: 1px solid #222;
    padding-bottom: 30px;

    .flexbox-service {
      margin-top: 30px;

      .service-heading {
        margin-bottom: 10px;

        p {
          text-transform: uppercase;
          color: #fff;
          font-size: 13px;
          font-weight: 500;
        }
      }

      p {
        color: #fff;
      }

      ul {
        li {
          padding-bottom: 5px;
          padding-top: 5px;

          a {
            color: #999;
            text-decoration: none;
            font-size: 13px;
          }

          &:first-child {
            padding-top: 0;
          }

          &:last-child {
            padding-bottom: 0;
          }
        }
      }

      &.service-about {
        max-width: 320px;

        .service-description {
          p {
            margin-bottom: 10px;
            color: #999;
            font-size: 13px;
          }
        }

        a {
          color: #fff;
          font-weight: 500;
          font-size: 20px;
          text-decoration: none;

          img {
            margin-right: 7px;
            max-height: 22px;
          }
        }
      }

      &.service-newsletter {
        text-align: right;

        .service-heading {
          p {
            font-size: 11px;
            color: #fff;
          }
        }

        p {
          font-size: 18px;
          color: #666;

          strong {
            font-size: 22px;
          }
        }

        .custom-search-input {
          max-width: 270px;

          input {
            border-radius: 0;
            border: 1px solid @color1;
            border-right: none;
            height: 35px;
            box-shadow: none;
            background: #0d0d0d;
            color: #fff;
          }

          ::-webkit-input-placeholder {
            color: #fff;
            text-transform: uppercase;
            font-size: 12px;
          }
          ::-moz-placeholder {
            color: #fff;
            text-transform: uppercase;
            font-size: 12px;
          }
          :-ms-input-placeholder {
            color: #fff;
            text-transform: uppercase;
            font-size: 12px;
          }
          input:-moz-placeholder {
            color: #fff;
            text-transform: uppercase;
            font-size: 12px;
          }

          button {
            background: @color1;
            height: 35px;
            width: 45px;
            border-radius: 0;
            border: 1px solid @color1;

            img {
              max-height: 20px;
            }
          }
        }
      }
    }
  }

  .footer-logo {
    margin-top: 30px;

    a {
      img {
        margin: 0 auto;
      }
    }
  }

  .footer-credits {
    margin-top: 30px;
    padding: 10px 0;

    p,
    a {
      color: #fff;
      font-size: 12px;
    }
  }
}

@media (max-width: @screen-md-max) {
  div#section-main-carousel {
    .main-carousel-contact {
      .contact-service {
        max-width: 940px;
      }
    }
  }

  div#section-offer {
    .offer-service {
      width: calc(100% / 2);
    }
  }
}

@media (max-width: @screen-sm-max) {
  div#section-header {
    nav.navbar {
      background: #0d0d0d;

      .navbar-collapse {
        border: none;
        box-shadow: none;

        ul.navbar-nav {
          li {
            a {
              padding: 15px;
              color: #fff;
              text-transform: uppercase;
              font-size: 14px;
            }

            &.item-search {
              height: auto;

              .search-form {
              }
            }

            &.shop-panel {
              height: auto;
            }
          }
        }
      }
    }
  }

  .products-flexbox {
    .product-service {
      width: 45%;
    }
  }

  .news-flexbox {
    article {
      width: 220px;

      .news-image {
        height: 129px;
      }
    }
  }

  div#section-main-carousel {
    .main-carousel-contact {
      .contact-service {
        max-width: 720px;
      }
    }
  }

  div#section-offer {
    .offer-service {
      width: 100%;
    }
  }

  div#section-colors {
    .bottom-flexbox {
      flex-wrap: wrap;

      .flexbox-service {
        width: 30%;

        &:nth-child(n + 4) {
          margin-top: 30px;
        }
      }
    }
  }

  div#section-footer {
    .footer-flexbox {
      .flexbox-service {
        &.service-about {
          width: 100%;
          max-width: 100%;
        }
      }
    }
  }
}

@media (max-width: @screen-xs-max) {
  .news-flexbox {
    flex-direction: column;
    align-items: center;

    article {
      width: 350px;
      max-width: 100%;

      .news-image {
        height: auto;
      }
    }
  }

  div#section-cta {
    margin-top: 0px;
  }

  div#section-offer {
    .offer-service {
      width: 100%;
      height: auto;
      text-align: center;

      .btn {
        align-self: center;
        margin-top: 20px;
      }

      .img-absolute {
        position: relative;
        align-self: center;
        bottom: auto;
        right: auto;
        margin-top: 30px;
      }
    }
  }

  div#section-colors {
    .colors-flexbox {
      .img-absolute {
        display: none;
      }

      .flexbox-description {
        width: 100%;
        margin-left: 0;
        text-align: center;
      }
    }

    .bottom-flexbox {
      .flexbox-service {
        width: 50%;
        text-align: center;
        flex-direction: column;

        .service-image {
          margin-right: 0;
          margin-bottom: 10px;
        }

        &:nth-child(n + 3) {
          margin-top: 30px;
        }
      }
    }
  }

  div#o-firmie-section-welcome {
    .welcome-flexbox {
      .img-absolute {
        position: relative;
        top: auto;
        right: auto;
        width: 100%;
        height: auto;
      }

      .flexbox-description {
        width: 100%;
      }
    }
  }

  div#produkt-section-content {
    .single-product-flexbox {
      flex-direction: column;
      align-items: center;

      .flexbox-gallery {
        width: 100%;
      }

      .flexbox-info {
        width: 100%;
        padding: 50px 20px;
      }
    }
  }

  div#section-footer {
    .footer-flexbox {
      flex-direction: column;

      .flexbox-service {
        &.service-about {
          width: 100%;
          max-width: 100%;
        }
      }
    }
  }
}

@media (max-width: 620px) {
  .products-flexbox {
    .product-service {
      width: 100%;
    }
  }
}
